//#region ODataApiGen ODataImports
import {
  NgModule
} from '@angular/core';//#endregion

//#region ODataApiGen Imports
import { ContainerService } from './OptiaApi/container.service';
import { AccountsService } from './OptiaApi/accounts.service';
import { AccountPersonsService } from './OptiaApi/accountpersons.service';
import { AddressesService } from './OptiaApi/addresses.service';
import { AgenciesService } from './OptiaApi/agencies.service';
import { AgencyAddressesService } from './OptiaApi/agencyaddresses.service';
import { AgencyBankDetailsService } from './OptiaApi/agencybankdetails.service';
import { AgencyDocumentsService } from './OptiaApi/agencydocuments.service';
import { AgencyMtdCalendarsService } from './OptiaApi/agencymtdcalendars.service';
import { AgencyPersonsService } from './OptiaApi/agencypersons.service';
import { AgencyPersonWfRolesService } from './OptiaApi/agencypersonwfroles.service';
import { AgencyReportingCategoriesService } from './OptiaApi/agencyreportingcategories.service';
import { AgencyReportingCombinationsService } from './OptiaApi/agencyreportingcombinations.service';
import { AgencyWorkersService } from './OptiaApi/agencyworkers.service';
import { AssignmentsService } from './OptiaApi/assignments.service';
import { AuditsService } from './OptiaApi/audits.service';
import { BankDetailsService } from './OptiaApi/bankdetails.service';
import { ChangeSetsService } from './OptiaApi/changesets.service';
import { ChangeSetGroupsService } from './OptiaApi/changesetgroups.service';
import { ChangeSetLinesService } from './OptiaApi/changesetlines.service';
import { CharitiesService } from './OptiaApi/charities.service';
import { CompaniesHouseDetailsService } from './OptiaApi/companieshousedetails.service';
import { CompaniesHouseDetailsAddressesService } from './OptiaApi/companieshousedetailsaddresses.service';
import { CompaniesHouseDetailsPersonsService } from './OptiaApi/companieshousedetailspersons.service';
import { ContractsService } from './OptiaApi/contracts.service';
import { ContractPersonsService } from './OptiaApi/contractpersons.service';
import { ContractReportingLabelsService } from './OptiaApi/contractreportinglabels.service';
import { CreditAssignmentsService } from './OptiaApi/creditassignments.service';
import { CreditChecksService } from './OptiaApi/creditchecks.service';
import { CreditLimitsService } from './OptiaApi/creditlimits.service';
import { CustomersService } from './OptiaApi/customers.service';
import { CustomerAddressesService } from './OptiaApi/customeraddresses.service';
import { CustomerAgenciesService } from './OptiaApi/customeragencies.service';
import { CustomerAgencyDocumentsService } from './OptiaApi/customeragencydocuments.service';
import { CustomerAgencyPersonsService } from './OptiaApi/customeragencypersons.service';
import { CustomerDocumentsService } from './OptiaApi/customerdocuments.service';
import { CustomerPersonsService } from './OptiaApi/customerpersons.service';
import { DataMismatchesService } from './OptiaApi/datamismatches.service';
import { DepartmentsService } from './OptiaApi/departments.service';
import { DocumentsService } from './OptiaApi/documents.service';
import { HistoricalReferencesService } from './OptiaApi/historicalreferences.service';
import { MiReportGroupsService } from './OptiaApi/mireportgroups.service';
import { MiReportGroupPersonsService } from './OptiaApi/mireportgrouppersons.service';
import { MiReportTypesService } from './OptiaApi/mireporttypes.service';
import { MiReportTypePersonsService } from './OptiaApi/mireporttypepersons.service';
import { MiReportTypeValidationRulesService } from './OptiaApi/mireporttypevalidationrules.service';
import { NotificationsService } from './OptiaApi/notifications.service';
import { NotificationPersonsService } from './OptiaApi/notificationpersons.service';
import { NotificationTemplatesService } from './OptiaApi/notificationtemplates.service';
import { OptiaNewsService } from './OptiaApi/optianews.service';
import { OptiaNewsDocumentsService } from './OptiaApi/optianewsdocuments.service';
import { OptiaNewsPersonsService } from './OptiaApi/optianewspersons.service';
import { PermissionsService } from './OptiaApi/permissions.service';
import { PeopleService } from './OptiaApi/people.service';
import { PersonAddressesService } from './OptiaApi/personaddresses.service';
import { PersonDepartmentsService } from './OptiaApi/persondepartments.service';
import { PersonPhoneNumbersService } from './OptiaApi/personphonenumbers.service';
import { PersonUserGroupsService } from './OptiaApi/personusergroups.service';
import { PhoneNumbersService } from './OptiaApi/phonenumbers.service';
import { PurchaseOrdersService } from './OptiaApi/purchaseorders.service';
import { RefDataItemsService } from './OptiaApi/refdataitems.service';
import { RefDataTypesService } from './OptiaApi/refdatatypes.service';
import { RegExesService } from './OptiaApi/regexes.service';
import { ReportingCategoriesService } from './OptiaApi/reportingcategories.service';
import { ReportingLabelsService } from './OptiaApi/reportinglabels.service';
import { SuppliersService } from './OptiaApi/suppliers.service';
import { SupplierAddressesService } from './OptiaApi/supplieraddresses.service';
import { SupplierAgenciesService } from './OptiaApi/supplieragencies.service';
import { SupplierBankDetailsService } from './OptiaApi/supplierbankdetails.service';
import { SupplierDocumentsService } from './OptiaApi/supplierdocuments.service';
import { SupplierPersonsService } from './OptiaApi/supplierpersons.service';
import { SupplierWorkerTypesService } from './OptiaApi/supplierworkertypes.service';
import { TradingStylesService } from './OptiaApi/tradingstyles.service';
import { TransfersService } from './OptiaApi/transfers.service';
import { TransferAgenciesService } from './OptiaApi/transferagencies.service';
import { TransferMiReportTypesService } from './OptiaApi/transfermireporttypes.service';
import { TransferLogsService } from './OptiaApi/transferlogs.service';
import { TransferRevisionsService } from './OptiaApi/transferrevisions.service';
import { UserGroupsService } from './OptiaApi/usergroups.service';
import { UserGroupPermissionsService } from './OptiaApi/usergrouppermissions.service';
import { WfActionsService } from './OptiaApi/wfactions.service';
import { WfInstancesService } from './OptiaApi/wfinstances.service';
import { WfOutcomesService } from './OptiaApi/wfoutcomes.service';
import { WfPreRequisitesService } from './OptiaApi/wfprerequisites.service';
import { WfProcessesService } from './OptiaApi/wfprocesses.service';
import { WfProcessWfSchedulesService } from './OptiaApi/wfprocesswfschedules.service';
import { WfRolesService } from './OptiaApi/wfroles.service';
import { WfRulesService } from './OptiaApi/wfrules.service';
import { WfSchedulesService } from './OptiaApi/wfschedules.service';
import { WfTasksService } from './OptiaApi/wftasks.service';
import { WfTaskContactTypesService } from './OptiaApi/wftaskcontacttypes.service';
import { WfTaskGroupsService } from './OptiaApi/wftaskgroups.service';
import { WfTaskRolesService } from './OptiaApi/wftaskroles.service';
import { WfTaskTabsService } from './OptiaApi/wftasktabs.service';
import { WfTaskTemplatesService } from './OptiaApi/wftasktemplates.service';
import { WfTaskWfSchedulesService } from './OptiaApi/wftaskwfschedules.service';
import { WfTemplatesService } from './OptiaApi/wftemplates.service';
import { WorkersService } from './OptiaApi/workers.service';
import { WorkerAddressesService } from './OptiaApi/workeraddresses.service';
import { WorkerBankDetailsService } from './OptiaApi/workerbankdetails.service';
import { WorkerDocumentsService } from './OptiaApi/workerdocuments.service';
import { WorkerPersonsService } from './OptiaApi/workerpersons.service';
import { WorkerPhoneNumbersService } from './OptiaApi/workerphonenumbers.service';
import { WorkerTypesService } from './OptiaApi/workertypes.service';
import { WorkerTypeBankDetailsService } from './OptiaApi/workertypebankdetails.service';
import { WorkerTypeDocumentsService } from './OptiaApi/workertypedocuments.service';
import { WorkerTypePersonsService } from './OptiaApi/workertypepersons.service';
import { WorkerTypeReportingLabelsService } from './OptiaApi/workertypereportinglabels.service';
import { CustomerDetailsService } from './OptiaApi/customerdetails.service';
import { LoggedInUserDTOService } from './OptiaApi/loggedinuserdto.service';
import { PermissionDTOService } from './OptiaApi/permissiondto.service';
import { SetPasswordDTOService } from './OptiaApi/setpassworddto.service';
import { CustomerDetailsFilterDtoService } from './OptiaApi/customerdetailsfilterdto.service';
import { WfActionOutstandingEdmService } from './OptiaApi/wfactionoutstandingedm.service';
import { ActionsByPersonGuidService } from './OptiaApi/actionsbypersonguid.service';
import { CustomerDetailEdmService } from './OptiaApi/customerdetailedm.service';
import { CustomerAgencyDetailsService } from './OptiaApi/customeragencydetails.service';
//#endregion

@NgModule({
  providers: [
//#region ODataApiGen Providers
    ContainerService,
    AccountsService,
    AccountPersonsService,
    AddressesService,
    AgenciesService,
    AgencyAddressesService,
    AgencyBankDetailsService,
    AgencyDocumentsService,
    AgencyMtdCalendarsService,
    AgencyPersonsService,
    AgencyPersonWfRolesService,
    AgencyReportingCategoriesService,
    AgencyReportingCombinationsService,
    AgencyWorkersService,
    AssignmentsService,
    AuditsService,
    BankDetailsService,
    ChangeSetsService,
    ChangeSetGroupsService,
    ChangeSetLinesService,
    CharitiesService,
    CompaniesHouseDetailsService,
    CompaniesHouseDetailsAddressesService,
    CompaniesHouseDetailsPersonsService,
    ContractsService,
    ContractPersonsService,
    ContractReportingLabelsService,
    CreditAssignmentsService,
    CreditChecksService,
    CreditLimitsService,
    CustomersService,
    CustomerAddressesService,
    CustomerAgenciesService,
    CustomerAgencyDocumentsService,
    CustomerAgencyPersonsService,
    CustomerDocumentsService,
    CustomerPersonsService,
    DataMismatchesService,
    DepartmentsService,
    DocumentsService,
    HistoricalReferencesService,
    MiReportGroupsService,
    MiReportGroupPersonsService,
    MiReportTypesService,
    MiReportTypePersonsService,
    MiReportTypeValidationRulesService,
    NotificationsService,
    NotificationPersonsService,
    NotificationTemplatesService,
    OptiaNewsService,
    OptiaNewsDocumentsService,
    OptiaNewsPersonsService,
    PermissionsService,
    PeopleService,
    PersonAddressesService,
    PersonDepartmentsService,
    PersonPhoneNumbersService,
    PersonUserGroupsService,
    PhoneNumbersService,
    PurchaseOrdersService,
    RefDataItemsService,
    RefDataTypesService,
    RegExesService,
    ReportingCategoriesService,
    ReportingLabelsService,
    SuppliersService,
    SupplierAddressesService,
    SupplierAgenciesService,
    SupplierBankDetailsService,
    SupplierDocumentsService,
    SupplierPersonsService,
    SupplierWorkerTypesService,
    TradingStylesService,
    TransfersService,
    TransferAgenciesService,
    TransferMiReportTypesService,
    TransferLogsService,
    TransferRevisionsService,
    UserGroupsService,
    UserGroupPermissionsService,
    WfActionsService,
    WfInstancesService,
    WfOutcomesService,
    WfPreRequisitesService,
    WfProcessesService,
    WfProcessWfSchedulesService,
    WfRolesService,
    WfRulesService,
    WfSchedulesService,
    WfTasksService,
    WfTaskContactTypesService,
    WfTaskGroupsService,
    WfTaskRolesService,
    WfTaskTabsService,
    WfTaskTemplatesService,
    WfTaskWfSchedulesService,
    WfTemplatesService,
    WorkersService,
    WorkerAddressesService,
    WorkerBankDetailsService,
    WorkerDocumentsService,
    WorkerPersonsService,
    WorkerPhoneNumbersService,
    WorkerTypesService,
    WorkerTypeBankDetailsService,
    WorkerTypeDocumentsService,
    WorkerTypePersonsService,
    WorkerTypeReportingLabelsService,
    CustomerDetailsService,
    CustomerAgencyDetailsService,
    LoggedInUserDTOService,
    PermissionDTOService,
    SetPasswordDTOService,
    CustomerDetailsFilterDtoService,
    WfActionOutstandingEdmService,
    ActionsByPersonGuidService,
    CustomerDetailEdmService//#endregion
  ]
})
export class Optia2023OdataModule { }