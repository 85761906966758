import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table'
import { MatDialogModule } from '@angular/material/dialog';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent, MsalModule, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { MsalGuard } from './aadb2cauth/msal.guard';
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './home/home.component';
import { GuardedComponent } from './guarded/guarded.component';

import { loginRequest, msalConfig, protectedResources } from './aadb2cauth/auth-config';

import { IInactivityLogoutConfig, inactivityConfig, INACTIVITY_CONFIG } from './inactivity-logout-config'

import { ODataModule } from 'angular-odata';
import { Optia2023OdataConfig } from './odata/optia2023-odata.config';
import { Optia2023OdataModule } from './odata/optia2023-odata.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CurrencyPipe, DatePipe } from '@angular/common';
import {BreadcrumbModule} from 'xng-breadcrumb';
import { Optia2023PublicClientApplication } from './aadb2cauth/optia2023-public-client-application';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { PageNotFoundComponent } from './page-notfound/page-notfound.component';
import { RequestInterceptor } from './shared/services/interceptor.service';
import { CustomDateAdapter } from './shared/adapters/custom-date-adapter';
import { Platform } from '@angular/cdk/platform';
import { MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material/datepicker';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';



/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new Optia2023PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export function InactivityLogoutConfigFactory(): IInactivityLogoutConfig {
  return inactivityConfig;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(protectedResources.backendApi.endpoint, protectedResources.backendApi.scopes);
return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidenavComponent,
    LogoutComponent,
    GuardedComponent,
    PageNotFoundComponent
  ],
  imports: [
    CurrencyPipe,
    DatePipe,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule, 
    MatDialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatListModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectModule,    
    MatSnackBarModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    FormsModule,
    MsalModule,
    ODataModule.forRoot(Optia2023OdataConfig),
    Optia2023OdataModule,
    BreadcrumbModule,
    MatNativeDateModule
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ],
  providers: [
    {
      provide: LOCALE_ID, 
      useValue: 'en-GB' 
    },
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'en-GB' 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: INACTIVITY_CONFIG,
      useFactory: InactivityLogoutConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: MAT_DATEPICKER_SCROLL_STRATEGY,
      useFactory: (scrollStrategyOptions: ScrollStrategyOptions) => scrollStrategyOptions.noop,
      deps: [ScrollStrategyOptions]
    },
    CurrencyPipe,
    DatePipe,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideEnvironmentNgxMask(maskConfigFunction)
  ]
})
export class AppModule { 
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
