import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion


//#region ODataApiGen Imports
import { CustomerAgencyDetailEdm } from './DataDomain/EFEntities/Customer/customeragencydetailedm.complex';
//#endregion

@Injectable()
export class CustomerAgencyDetailsService extends ODataEntitySetService<CustomerAgencyDetailEdm> {
  constructor(client: ODataClient) {
    super(client, 'CustomerAgencyDetails', 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyDetailEdm');
  }
  //#region ODataApiGen Actions
  public searchCustomerAgencyDetails(): ODataActionResource<{filter: string, warningFilter: number | undefined, typeFilter: number | undefined, statusFilter: number | undefined, statusAccountFilter: number | undefined, skip: number, take: number, sortColumn: string, sortOrder: string,}, CustomerAgencyDetailEdm> { 
    return this.entities().action<{filter: string, warningFilter: number | undefined, typeFilter: number | undefined, statusFilter: number | undefined, statusAccountFilter: number | undefined, skip: number, take: number, sortColumn: string, sortOrder: string,}, CustomerAgencyDetailEdm>('OptiaApi.SearchCustomerAgencyDetails');
  }
  public callSearchCustomerAgencyDetails(filter: string, warningFilter: number | undefined, typeFilter: number | undefined, statusFilter: number | undefined, statusAccountFilter: number | undefined, skip: number, take: number, sortColumn: string, sortOrder: string, options?: ODataActionOptions<CustomerAgencyDetailEdm>) {
    return this.callAction<{filter: string, warningFilter: number | undefined, typeFilter: number | undefined, statusFilter: number | undefined, statusAccountFilter: number | undefined, skip: number, take: number, sortColumn: string, sortOrder: string,}, CustomerAgencyDetailEdm>(
      {filter, warningFilter, typeFilter, statusFilter, statusAccountFilter, skip, take, sortColumn, sortOrder}, 
      this.searchCustomerAgencyDetails(), 
      'entities', options) as Observable<ODataEntities<CustomerAgencyDetailEdm>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  //#endregion
}
